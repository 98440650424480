import React from "react"
import Layout from "../components/layout"
import DemoFooter from "../components/demoFooter"
import Hero from "../components/hero"
import HeroImage from '../images/OurStory-Image-01.jpg';
import SEO from "../components/seo"

export default function(){
  return (
    <Layout>
      <SEO title={'Our Story'}/>
      <Hero image={HeroImage}>
        <div className={"col col-lg-6 pr-lg-5 align-self-center text-white py-5"}>
          <h1
            style={{
              fontFamily: "Anton",
              fontWeight: 400,
              fontSize: "5rem",
            }}
          >
            IT TAKES AN EXPERIENCED TEAM TO WIN ONLINE
          </h1>
        </div>
      </Hero>

      <div className={"row justify-content-center my-5"}>
        <div className={"col col-md-8 text-center border-top border-bottom"}>
          <h5 style={{ lineHeight: "1.9em" }} className={"font-weight-bold"}>
            With over 16 years of combined powersports and software solutions experience, the team at PSS offers a unique understanding of our incredible industry and its data needs. We are riders, racers, and gear enthusiasts. We’ve been
            in the parts game, racing game, and technology game since the early days of ecommerce. Most of all, we’ve created a culture where everything we do is focused on helping you win the online sales game. All that and we’re
            friendly, honest, and passionate about your business. Sure, there are other data solutions out there. But there’s only one Powersports Support. </h5>
        </div>
      </div>

      <DemoFooter/>
    </Layout>
  )
}
